<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card
      outlined>
      <v-card-text>
      
        <v-row class="mt-0">
            <!-- id -->
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="tariff.id"
              label="Id."
              dense
              disabled
            ></v-text-field>
          </v-col>

          <!-- ocpi_id -->
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              v-model="tariff.ocpi_id"
              label="OCPI Id."
              disabled
              dense
            ></v-text-field>
          </v-col>
          
          <!-- type -->
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="tariff.type"
              :items="tariffTypes"
              clearable
              label="Type"
              dense
            ></v-select>
          </v-col>

          <!-- currency -->
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="tariff.currency"
              label="Currency"
              :rules="[v => !!v || 'Currency is required']"
              dense
            ></v-text-field>
          </v-col>

          <!-- min_price -->
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="tariff.min_price"
              label="Min price"
              dense
            ></v-text-field>
          </v-col>

          <!-- max_price -->
          <v-col
            cols="12"
            sm="6"
            md="2"
          >
            <v-text-field
              v-model="tariff.max_price"
              label="Max price"
              dense
            ></v-text-field>
          </v-col>

          <!-- tariff_alt_url -->
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              v-model="tariff.tariff_alt_url"
              label="Url"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        
        <!-- tariff_alt_text -->
        <v-row>
          <v-col class="pb-2"
            cols="12"
            sm="12"
            md="12"
          >
            <span class="item-label">Description</span>
          </v-col>
        </v-row>
        <template v-if="tariff.tariff_alt_text">
              <v-row v-for="(tariff_alt_text, index) in tariff.tariff_alt_text.a" :key="index" class="mt-0 pt-0">
                  <v-col class="pt-1"
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-row class="pt-3 pl-3">
                      <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ index + 1 }} - </span>
                      <v-text-field
                          v-model="tariff.tariff_alt_text.a[index].language"
                          label="Language"
                          hide-details
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col class="pt-1 pl-6"
                      cols="12"
                      sm="6"
                      md="8"
                  >
                    <v-row class="pt-3">
                      <v-text-field
                          v-model="tariff.tariff_alt_text.a[index].text"
                          label="Text"
                          hide-details
                      ></v-text-field>
                      <v-btn class="mt-5 ml-2"
                        icon
                        @click="() => tariff.tariff_alt_text.a.splice(index, 1)">
                          <v-icon samll color="grey">mdi-close</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
              </v-row>
              <v-row :set="newItemIndex = tariff.tariff_alt_text.a.length" class="mt-0 pt-0">
                  <v-col
                      class="pt-1"
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-row class="pt-3 pl-3">
                      <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ newItemIndex + 1 }} - </span>
                      <v-btn class="mt-4"
                        text
                        max-width="auto"
                        @click="() => tariff.tariff_alt_text.a.push({ language: null, text: null })">
                        <span color="grey">Add +</span>
                      </v-btn>
                    </v-row>
                  </v-col>
              </v-row>
        </template>

        <!-- elements -->
        <v-row>
          <v-col class="pb-6"
            cols="12"
            sm="12"
            md="12"
          >
            <div class="d-flex flex-row">
                <span class="item-label" :style="(!validateElements(tariff)) ? 'color: var(--v-error-base) !important;' : ''">Elements</span>
                <transition name="slide-fade">
                  <span class="text-caption ml-3" style="color: var(--v-error-base) !important; line-height: 22px;" v-if="!validateElements(tariff)">Tariff must contain at least one element</span>
                </transition>
            </div>
          </v-col>
        </v-row>
        <template v-if="tariff.elements">
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(element, index) in tariff.elements.a"
                  :key="index"
                >
                  <v-expansion-panel-header class="elements-expansion-panel-header">
                    {{ index + 1 }} - 
                    <v-chip v-for="(price_component, index) in element.price_components" :key="index" class="ml-2" outlined color="accent">
                      <template v-if="price_component.type=='ENERGY'">{{ price_component.price }} {{ tariff.currency }} x {{ price_component.step_size }} kWh</template>
                      <template v-else-if="price_component.type=='FLAT'">{{ price_component.price }} {{ tariff.currency }}</template>
                      <template v-else-if="price_component.type=='PARKING_TIME'">{{ price_component.price }} {{ tariff.currency }} x {{ price_component.step_size }} parking seconds</template>
                      <template v-else-if="price_component.type=='TIME'">{{ price_component.price }} {{ tariff.currency }} x {{ price_component.step_size }} parking seconds</template>
                    </v-chip>
                    <div v-for="(value, key, index) in element.restrictions" :key="`res-${index}`" style="width=auto;">
                      <span class="text-caption ml-3">{{ key }}: </span><span>{{ value }}</span>
                    </div>
                    <transition name="slide-fade">
                      <span class="text-caption ml-3" style="color: var(--v-error-base) !important; line-height: 22px;" v-if="!validateElementInputs(element)">Element has mandatory inputs without reporting</span>
                    </transition>
                    <v-btn class="mr-2" style="flex: 1 1 auto!important; margin-left: auto; max-width: 28px;"
                      icon
                      small
                      @click.stop="() => tariff.elements.a.splice(index, 1)">
                        <v-icon samll color="grey">mdi-delete</v-icon>
                    </v-btn>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-6">
                      <!-- price_components -->
                      <v-row>
                        <span class="text-caption mt-5">Price components</span>
                      </v-row>
                      <template v-if="element.price_components">
                            <v-row v-for="(price_component, index) in element.price_components" :key="index" class="mt-0 pt-2">
                                <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ index + 1 }} - </span>
                                <v-select
                                  v-model="element.price_components[index].type"
                                  :items="priceComponentTypes"
                                  @change="(newType) => { if(newType == 'FLAT') { element.price_components[index].step_size = 1 } }"
                                  :rules="[v => !!v || 'Type is required']"
                                  clearable
                                  label="Type"
                                ></v-select>
                                <v-text-field
                                    class="pl-2"
                                    v-model.number="element.price_components[index].price"
                                    type="number"
                                    :rules="[v => v !== null && v !== '' && typeof v !== 'undefined' || 'Price is required']"
                                    label="Price"
                                ></v-text-field>
                                <v-text-field
                                    class="pl-2"
                                    v-model.number="element.price_components[index].vat"
                                    type="number"
                                    label="VAT"
                                    clearable
                                ></v-text-field>
                                <v-text-field
                                    class="pl-2"
                                    v-model.number="element.price_components[index].step_size"
                                    type="number" 
                                    label="Step size"
                                    :rules="[v => !!v || 'Step size is required']"
                                    disabled
                                ></v-text-field>
                                <v-btn class="mt-5 ml-2"
                                  icon
                                  @click="() => element.price_components.splice(index, 1)">
                                    <v-icon samll color="grey">mdi-close</v-icon>
                                </v-btn>
                            </v-row>
                            <v-row :set="newItemIndex = element.price_components.length" class="mt-0 pt-2">
                              <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ newItemIndex + 1 }} - </span>
                              <v-btn class="mt-4"
                                text
                                max-width="auto"
                                :disabled="newItemIndex > 0"
                                @click="() => element.price_components.push({ type: null, price: null, step_size: 1 })">
                                <span color="grey">Add +</span>
                              </v-btn>
                          </v-row>
                      </template>

                      <!-- restrictions -->
                      <v-row class="pt-2 pl-0">
                          <v-col class="pt-1 pl-0 pb-0"
                              cols="12"
                              sm="12"
                              md="12"
                          >
                              <span class="text-caption ml-0 pl-0">Restrictions</span>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model="tariff.elements.a[index].restrictions.start_time"
                                label="Start time"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model="tariff.elements.a[index].restrictions.end_time"
                                label="End time"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model="tariff.elements.a[index].restrictions.start_date"
                                label="Start date"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model="tariff.elements.a[index].restrictions.end_date"
                                label="End date"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.min_kwh"
                                type="number" 
                                label="Min kwh"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.max_kwh"
                                type="number"
                                label="Max kwh"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.min_power"
                                type="number"
                                label="Min power"
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.max_power"
                                type="number"
                                label="Max power"
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.min_duration"
                                type="number"
                                label="Min duration"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="3"
                          >
                            <v-text-field
                                v-model.number="tariff.elements.a[index].restrictions.max_duration"
                                type="number"
                                label="Max duration"
                                disabled
                                hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col class="pt-0 pl-0"
                              cols="12"
                              sm="3"
                              md="6"
                          >
                            <v-select
                              v-model="tariff.elements.a[index].restrictions.day_of_week"
                              :items="weekdays"
                              label="Day of week"
                              disabled
                              clearable
                              multiple
                            ></v-select>
                          </v-col>
                      </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :set="newItemIndex = tariff.elements.a.length">
                  <v-expansion-panel-header class="elements-expansion-panel-header elements-expansion-panel-header-add" hide-actions>
                    {{ newItemIndex + 1 }} -
                    <v-btn class="ml-2"
                      text
                      max-width="auto"
                      @click="() => tariff.elements.a.push({ price_components: [ { type: 'ENERGY', step_size: 1 } ], restrictions: {} })">
                      <span color="grey">Add +</span>
                    </v-btn>
                  </v-expansion-panel-header>
                </v-expansion-panel>
              </v-expansion-panels>
        </template>
      </v-card-text>
    </v-card>

    <v-card 
      class="mt-4"
      outlined
    >
      <h4 class="pt-3 pl-5">Configurations</h4>
      <tariff-configurations-edit ref="tariffConfigForm" v-model="tariffConfiguration"></tariff-configurations-edit>
    </v-card>

    <div class="text-right mt-4">
      <v-btn class="cancel-btn mr-2"
          depressed @click="$emit('cancel')">
          CANCEL
      </v-btn>
      <v-btn depressed class="accent" :disabled="!valid" @click="save(tariff, tariffConfiguration)">
          <v-icon left>mdi-content-save</v-icon> SAVE
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import AppBar from "@/components/AppBar"
import TariffConfigurationsEdit from "@/components/tariff/TariffConfigurations.edit"

export default {
    name: "tariff-edit-dialog",
    components: {
      AppBar,
      TariffConfigurationsEdit
    },
    props: {
      value: {
        required: false
      }
    },
    data() {
        return {
          valid: true,
          tariff: { provider_id: 1, country_code: 'ES', party_id: 'WEN', tariff_alt_text: { a: [] }, elements: { a: [] }  },
          tariffConfiguration: {},
          tariffTypes: [
              "AD_HOC_PAYMENT",
              "PROFILE_CHEAP",
              "PROFILE_FAST",
              "PROFILE_GREEN",
              "REGULAR",
          ],
          priceComponentTypes: [
            { text: 'ENERGY',value: 'ENERGY', disabled: false },
            { text: 'FLAT', value: 'FLAT', disabled: true },
            { text: 'PARKING_TIME', value: 'PARKING_TIME', disabled: true },
            { text: 'TIME', value: 'TIME', disabled: true }
          ],
          weekdays: [
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
            "SUNDAY"
          ]
        }
    },
    created(){
      this.loadFormData(this.value)
    },
    watch: {
      value(tariff){
        this.loadFormData(tariff)
      }
    },
    methods: {
      loadFormData(tariff){
        if(!tariff) {
          this.tariff = { provider_id: 1, country_code: 'ES', party_id: 'WEN', tariff_alt_text: { a: [] }, elements: { a: [] }  }
          this.tariff = {}
          return
        }

        this.tariff = (({tariff_configuration, ...tariff}) => tariff) (tariff)
        this.tariffConfiguration = tariff.tariff_configuration || {}
      },
      save(tariff, tariffConfiguration){
       
        this.pruneEmpty(tariff)
        const data = { ...tariff, tariffConfiguration }
        const isValid = this.validate(data)
        if(!isValid) { return }
        
        if (data.id) {
          this.$api.put(`/tariffs/${data.id}`, data)
            .then(() => {
                this.showSuccess("Changes successfully saved")
                this.$emit('updated', data)
            })
            .catch(this.showError)
        } else {
          this.$api.post(`/tariffs`, data)
            .then(resp => {
              this.showSuccess("Changes successfully saved")
              this.$emit('updated', resp.data)
            })
            .catch(this.showError)
        }
      },
      validate(data){
        let isValid = this.validateTariff(data)
        if (!this.$refs.tariffConfigForm.validate()) { isValid = false }

        return isValid
      },
      validateTariff(data){
        let isValid = this.$refs.form.validate()
        if (!this.validateElements(data)) { isValid = false }
        for (const element of data.elements.a) {
          if (!this.validateElementInputs(element)) { isValid = false }
        }

        return isValid
      },
      validateElements(data){
        return data.elements && data.elements.a && data.elements.a.length > 0
      },
      validateElementInputs(data) {
        return data.price_components?.find(price_component => price_component.type 
          && (price_component.price !== null && price_component.price !== '' && typeof price_component.price !== 'undefined') 
          && price_component.step_size)
      },
      pruneEmpty(obj) {
        for (const key in obj) {
          const value = obj[key]
          if (typeof value === "string" && value == "") {
            delete obj[key]
          } else if ( value instanceof Object || value instanceof Array ) { this.pruneEmpty(value) }
        }
      }
    }
}
</script>

<style scoped>
  .elements-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
    flex: 0 1 auto!important;
  }
  >>> .elements-expansion-panel-header > .v-expansion-panel-header__icon {
    margin-left: 0;
  }
  >>> .elements-expansion-panel-header-add > .v-expansion-panel-header__icon {
    margin-left: auto;
  }

  >>> div .v-expansion-panel::before {
    box-shadow: none !important;
  }
  >>> .v-expansion-panels .v-expansion-panel {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
    border-top: none;
  }
  >>> div .v-expansion-panel:first-child {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  >>> div .v-expansion-panel:last-child {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  >>> .v-expansion-panel-header {
      height: 48px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
  >>> .v-expansion-panel--active > .v-expansion-panel-header {
      min-height: 48px;
  }
  >>> .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
          margin-top: 0px;
  }
  .cancel-btn {
    color: black !important;
    border: 1px solid #ccc!important;
  }
  .item-label {
    font-size: 16px!important;
  }

  .slide-fade-enter-active {
  transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-10px);
    opacity: 0;
  }
</style>